// filters.js
import moment from "moment";

export default {
  install(app) {
    app.config.globalProperties.$filters = {
      date(date) {
        if (!date) return "...";
        return moment(date).format("L");
      },
      datetime(date) {
        if (!date) return "...";
        return moment(date).format("DD/MM/YYYY HH:mm");
      },
      dontShowZero(amount) {
        return amount === 0 ? "" : amount;
      },
      toCurrency(value) {
        value = parseFloat(value);
        if (isNaN(value) || value === 0) {
          return "";
        }

        const formatter = new Intl.NumberFormat("nl-BE", {
          style: "currency",
          currency: "EUR",
          minimumFractionDigits: 2,
        });

        return formatter.format(value).replace("€", "");
      },
    };
  },
};
